import { getPageMetaData } from './get-page-meta-data';
import { query, queryAll } from './helpers';
import { getSettings } from './settings';
import { onLdClientReady } from './wait-for';

import {
	DATA_LOCATION,
	PRIMARY_BANNER,
	VEHICLE_BADGE,
	VEHICLE_MEDIA,
	VEHICLE_PAYMENTS,
	VEHICLE_PRICING,
	VEHICLE_CTAS,
	VEHICLE_BADGE_CUSTOM,
	VEHICLE_MEDIA_CUSTOM,
	VEHICLE_PAYMENTS_CUSTOM,
	VEHICLE_PRICING_CUSTOM,
	VEHICLE_CTAS_CUSTOM
} from '../edit/constants.js';

const hiddenContentPlaceholderClasses =
	'ddc-content web-api-added-placeholder hidden';

const hasLocation = (location, target) => {
	return (
		query(`[data-location="${target}"]`, location) ||
		query(`[data-location="${target}-custom"]`, location)
	);
};

const createNewLocation = (elementType, locationName, classes) => {
	const el = document.createElement(elementType);
	el.setAttribute(DATA_LOCATION, locationName);
	el.setAttribute('class', 'hidden');
	if (classes) {
		classes.split(' ').forEach(className => {
			el.classList.add(className);
		});
	}
	return el;
};

const getTargetLocation = (locations, name, customName) => {
	return locations.includes(name) ? customName : `${name} ${customName}`;
};

const insertAfter = (referenceNode, newElement) => {
	referenceNode.parentNode.insertBefore(newElement, referenceNode.nextSibling);
};

const insertBefore = (referenceNode, newElement) => {
	referenceNode.parentNode.insertBefore(newElement, referenceNode);
};

const insertInto = (referenceNode, newElement) => {
	referenceNode.appendChild(newElement);
};

const getVehicleCtaWidgets = () => {
	const ctaPortals = queryAll('.ddc-vehicle-cta-summary');
	return ctaPortals.length > 0 ? ctaPortals : queryAll('[data-widget-name="ws-vehicle-ctas"]');
};

const addGridViewLocations = () => {
	// Add CTA locations for the Groovy based Grid View SRP.
	// This is a temporary solution until we sunset this SRP.
	queryAll('.gv-pricing').forEach(location => {
		if (!hasLocation(location, VEHICLE_MEDIA)) {
			location.appendChild(
				createNewLocation(
					'ul',
					VEHICLE_MEDIA,
					'list-unstyled d-flex flex-column justify-content-center w-100'
				)
			);
		}
		if (!hasLocation(location, VEHICLE_CTAS)) {
			location.appendChild(createNewLocation('div', VEHICLE_CTAS));
		}
		if (!hasLocation(location, VEHICLE_PRICING)) {
			location.appendChild(createNewLocation('div', VEHICLE_PRICING));
		}
		if (!hasLocation(location, VEHICLE_BADGE)) {
			location.appendChild(
				createNewLocation(
					'ul',
					VEHICLE_BADGE,
					'list-unstyled d-flex flex-wrap justify-content-center'
				)
			);
		}
	});
};

const addLegacySearchResultsPageLocations = () => {
	// Add CTA locations for the Groovy based Grid View SRP.
	// This is a temporary solution until we sunset this SRP.
	queryAll('.hproduct .media').forEach(location => {
		if (!hasLocation(location, VEHICLE_MEDIA)) {
			location.appendChild(
				createNewLocation(
					'ul',
					VEHICLE_MEDIA,
					'list-unstyled d-flex flex-column'
				)
			);
		}
	});

	queryAll('.hproduct .calloutDetails ul').forEach(location => {
		if (!hasLocation(location, VEHICLE_BADGE)) {
			location.setAttribute(DATA_LOCATION, VEHICLE_BADGE);
		}
	});
};

const overrideLocationName = (locationName, selector) => {
	if (!hasLocation(document, locationName)) {
		const target = query(selector);
		if (target) {
			target.setAttribute(DATA_LOCATION, locationName);
		}
	}
};

// Add vehicle-media and vehicle-badge locations for legacy VDP.
const addLegacyVehicleDetailsPageLocations = async (
	pageData,
	targetLocations
) => {
	const vehicleMediaLocationName = getTargetLocation(
		targetLocations,
		VEHICLE_MEDIA,
		VEHICLE_MEDIA_CUSTOM
	);
	const vehicleBadgeLocationName = getTargetLocation(
		targetLocations,
		VEHICLE_BADGE,
		VEHICLE_BADGE_CUSTOM
	);

	if (pageData.detailPage && !hasLocation(document, vehicleMediaLocationName)) {
		const vehicleMediaInsertTarget = query(
			'[data-widget-name="inventory-detail-highlighted-features"]'
		);

		if (vehicleMediaInsertTarget) {
			const newLocation = createNewLocation(
				'ul',
				vehicleMediaLocationName,
				'list-unstyled web-api-added-placeholder'
			);

			insertBefore(vehicleMediaInsertTarget, newLocation);
		}
	}

	overrideLocationName(vehicleBadgeLocationName, '.calloutDetails ul');
	overrideLocationName(
		vehicleMediaLocationName,
		'.osiris-third-party-include [data-location="vehicle-media"]'
	);
};

// Add any missing primary-banner locations.
const addPrimaryBannerLocation = async pageData => {
	if (!hasLocation(document, PRIMARY_BANNER)) {
		const newLocation = createNewLocation(
			'div',
			PRIMARY_BANNER,
			hiddenContentPlaceholderClasses
		);

		let primaryBannerInsertTarget;

		if (pageData.detailPage) {
			primaryBannerInsertTarget = query('[data-widget-id="tps-mediabot1"]')
				? query('[data-widget-id="tps-mediabot1"]')
				: query('[data-widget-name="ws-quick-specs"]');

			// For legacy VDP support.
			if (!primaryBannerInsertTarget) {
				primaryBannerInsertTarget = query(
					'[data-widget-name="inventory-detail-highlighted-features"]'
				);
			}
		} else if (pageData.searchPage) {
			primaryBannerInsertTarget =
				query('[data-name="srp-wrapper-page-title"]') ||
				query('[data-widget-name="inventory-listing-default"]');
		}

		if (primaryBannerInsertTarget) {
			insertBefore(primaryBannerInsertTarget, newLocation);
		}
	}
	return true;
};

const addSecondaryContentLocation = async pageData => {
	if (!pageData.detailPage) {
		return true;
	}
	if (!hasLocation(document, 'secondary-content')) {
		const newLocation = createNewLocation(
			'div',
			'secondary-content',
			hiddenContentPlaceholderClasses
		);

		const secondaryContentInsertTarget = query(
			'[data-widget-id="detailed-specs1"]'
		);

		if (secondaryContentInsertTarget) {
			insertAfter(secondaryContentInsertTarget, newLocation);
		}
	}
	return true;
};

const addContentCtaLocations = async pageData => {
	if (!pageData.detailPage) {
		return true;
	}

	const vehicleCtaLocations = getVehicleCtaWidgets();

	vehicleCtaLocations.forEach((location, index) => {
		const dataLocationName = location.getAttribute('data-location');
		const position = index === 0 ? 'top' : 'bottom';
		location.setAttribute(
			'data-location',
			`${dataLocationName} vehicle-ctas-${position}`
		);
	});

	return true;
};

const urlToButtonIntents = new Map([
	['ASK', 'vehicle-ask-button'],
	['DRIVE', 'vehicle-drive-button']
]);

const addCallToActionLocation = async (pageData, targetLocations) => {
	if (!(pageData.detailPage || pageData.searchPage)) {
		return true;
	}

	// Adding behaviours to the links in links-lead1 in the VDP
	if (pageData.detailPage && query('[data-widget-id="links-lead1"]')) {
		const linksList = queryAll('[data-widget-id="links-lead1"] .btn');
		if (linksList.length > 0) {
			linksList.forEach(link => {
				const linkLabel = link.getAttribute('data-link-label');
				if (
					linkLabel &&
					urlToButtonIntents.has(linkLabel) &&
					!link.getAttribute(DATA_LOCATION)
				) {
					link.setAttribute(DATA_LOCATION, urlToButtonIntents.get(linkLabel));
				}
			});
		}
	}

	// This targets the react-based VDP. The react-based SRP includes vehicle-ctas locations
	// from the wsm-vehicle-cta-display module so they are not added by the API.
	if (pageData.detailPage && query('[data-widget-name="ws-vehicle-ctas"]')) {
		const ctaEditSites = getVehicleCtaWidgets();

		const vehicleCtaLocationName = getTargetLocation(
			targetLocations,
			VEHICLE_CTAS,
			VEHICLE_CTAS_CUSTOM
		);

		if (ctaEditSites.length > 0) {
			ctaEditSites.forEach(widget => {
				widget.setAttribute(DATA_LOCATION, vehicleCtaLocationName);
				const childLocations = queryAll(
					'[data-location~="vehicle-ctas"]',
					widget
				);
				childLocations.forEach(location => {
					location.removeAttribute(DATA_LOCATION);
					location.classList.add('mb-3');
				});
			});
		}

		// This only affects the legacy SRP and VDP.
	} else {
		let ctaEditSites = [];

		if (pageData.searchPage) {
			ctaEditSites = queryAll('.pricing-area .pricing');
		} else if (pageData.detailPage) {
			const pricingLocation = query('.inventory-detail-pricing .pricing');

			if (pricingLocation) {
				ctaEditSites.push(pricingLocation);
			}
		}

		ctaEditSites.forEach(ctaLocation => {
			const ctaInsertLocation = document.createElement('li');
			ctaInsertLocation.setAttribute(DATA_LOCATION, VEHICLE_CTAS);
			ctaInsertLocation.setAttribute(
				'class',
				'web-api-added-placeholder hidden mt-3'
			);
			insertInto(ctaLocation, ctaInsertLocation);
		});
	}
	return true;
};

const addVehiclePaymentsLocation = async (pageData, targetLocations) => {
	// Add payments locations in ws-detailed-pricing widget.
	if (pageData.detailPage) {
		const vehiclePaymentsLocationName = getTargetLocation(
			targetLocations,
			VEHICLE_PAYMENTS,
			VEHICLE_PAYMENTS_CUSTOM
		);

		if (!hasLocation(document, vehiclePaymentsLocationName)) {
			const vehiclePaymentsInsertTargets = queryAll(
				'[data-widget-name="ws-detailed-pricing"]'
			);

			vehiclePaymentsInsertTargets.forEach(paymentTarget => {
				const newLocation = createNewLocation(
					'div',
					vehiclePaymentsLocationName,
					`${hiddenContentPlaceholderClasses} ws-detailed-pricing`
				);
				insertAfter(paymentTarget, newLocation);
			});
		}
	}
	return true;
};

const addVehiclePricingLocation = async (pageData, targetLocations) => {
	// Add CTA locations in ws-vehicle-ctas widgets.
	if (pageData.detailPage) {
		const vehicleCtaLocationName = getTargetLocation(
			targetLocations,
			VEHICLE_CTAS,
			VEHICLE_CTAS_CUSTOM
		);
		const vehiclePricingLocationName = getTargetLocation(
			targetLocations,
			VEHICLE_PRICING,
			VEHICLE_PRICING_CUSTOM
		);

		const ctaEditSites = queryAll(
			`[data-location="${vehicleCtaLocationName}"]`
		);

		if (ctaEditSites.length > 0) {
			const ctaLocation = ctaEditSites[ctaEditSites.length - 1];

			const classes =
				pageData.layoutType === 'desktop'
					? 'ddc-content responsive-content-max-hide responsive-content-mid-hide responsive-content-min-hide box web-api-added-placeholder hidden'
					: hiddenContentPlaceholderClasses;

			const vehiclePricingLocation = createNewLocation(
				ctaLocation.tagName.toLowerCase(),
				vehiclePricingLocationName,
				classes
			);

			ctaLocation.classList.add('pb-0');

			insertAfter(ctaLocation, vehiclePricingLocation);
		}
	} else if (pageData.searchPage) {
		const pricingEditSites = queryAll('.pricing-area .pricing');

		pricingEditSites.forEach(pricingLocation => {
			const vehiclePricingLocation = createNewLocation(
				'li',
				VEHICLE_PRICING,
				'web-api-added-placeholder hidden mb-4'
			);
			insertInto(pricingLocation, vehiclePricingLocation);
		});
	}
	return true;
};

/**
 * Retrieves delayLoadVdpFooter value from session storage, if it was present in a WISE config
 * @returns { Promise<boolean> }
 */
const getDelayFromWiapiSettings = async () => {
	const pageAliasSettings = await getSettings(window.DDC.siteSettings.pageAlias);
	return pageAliasSettings.delayLoadVdpFooter || false;
}
/**
 * 1. Appends the 'mobile-vdp-footer' data-location to the links-list <ul>.
 * 2. Slides in the fixed-vdp-footer on a conditional delay.
 * @returns { Promise<true> }
 */
const addMobileVdpFooterLocation = async () => {
	const vdpFooter = query('.fixed-vdp-footer');
	const editLocation = query('.links-list ul', vdpFooter);
	if (editLocation) {
		editLocation.setAttribute('data-location', 'mobile-vdp-footer');

		// Ensure Launch Darkly is available before using flags.
		onLdClientReady(async () => {
			// Get the delay duration from Launch Darkly
			const flags = window.ldClient.allFlags();
			const flagDelayDuration = flags['mobile-vdp-footer-delay'] || 2000;
			const flagDelayToggle = flags['toggle-delay-vdp-footer'] || false;

			const shouldDelay = await getDelayFromWiapiSettings() && flagDelayToggle;

			// Delay if necessary
			const delayDuration = shouldDelay ? flagDelayDuration : 0;
			setTimeout(() => {
				vdpFooter?.classList?.add('in');
			}, delayDuration);
		});
	}
	return true;
};

export const populateEditSites = async () => {
	const pageData = await getPageMetaData();
	const dataTargetLocations = 'data-target-locations';
	let reactPortal;
	let targetLocations = [];

	if (pageData.detailPage) {
		reactPortal = query('.eprice-react-portal');
		targetLocations = reactPortal?.getAttribute(dataTargetLocations)
			? reactPortal?.getAttribute(dataTargetLocations)?.split(' ')
			: [];
	}
	if (pageData.searchPage && query('.inventory-listing-grid')) {
		addGridViewLocations();
	} else if (pageData.searchPage || pageData.detailPage) {
		await addPrimaryBannerLocation(pageData);
		await addCallToActionLocation(pageData, targetLocations);
		await addVehiclePaymentsLocation(pageData, targetLocations);
		await addVehiclePricingLocation(pageData, targetLocations);
		if (pageData.searchPage && query('.inventoryList')) {
			addLegacySearchResultsPageLocations();
		} else if (pageData.detailPage) {
			addLegacyVehicleDetailsPageLocations(pageData, targetLocations);
			await addSecondaryContentLocation(pageData);
			await addContentCtaLocations(pageData);
			if (pageData.layoutType === 'mobile') {
				await addMobileVdpFooterLocation();
			}
		}
	}
	return true;
};

export const remediateStylingIssues = async () => {
	const hasPriceButtonLocations = query('li.price-btn');

	if (hasPriceButtonLocations) {
		const priceButtonLocations = queryAll('li.price-btn');
		priceButtonLocations.forEach(location => {
			location.classList.add('clearfix');
		});
	}
	return true;
};